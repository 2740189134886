@import './app-variables';

.mat-button-wrapper {
  text-transform: uppercase;
}
.mat-card {
  padding: 16px !important;
}

mat-ink-bar.mat-ink-bar {
  height: 3px;
}

.mat-dialog-content {
  p {
    color: $foundational-color-7;
    font-size: 16px;
  }

  .mat-input-element,
  .mat-select-value-text * {
    color: $foundational-color-10;
    font-size: $base-font-size-web;
  }
  .mat-error span,
  .mat-error div,
  .mat-error p {
    font-size: $text-size-body-4;
    font-weight: $text-weight-rg;
    color: $text-color-error;
  }
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 100%, transparent 0%);
  background-size: unset;
  background-repeat: no-repeat;
}

.mat-input-element:disabled {
  color: $text-color-dark-4;
}

.mat-form-field-subscript-wrapper {
  margin-top: 4px !important;
}

.mat-warnings {
  .mat-form-field-ripple,
  .mat-form-field-underline {
    background-color: $xpo-warning-color !important;
  }

  .mat-form-field-label {
    color: $xpo-warning-color !important;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7) !important;
  background: white !important;
}

.mat-simple-snackbar {
  color: black !important;
}

.mat-form-field-suffix {
  top: 0.3em !important;
  color: #15141442;
  right: 0.8em !important;
}

.mat-dialog-container {
  margin-left: 2% !important;
}
