/* You can add global styles to this file, and also import other style files */

@import "app-variables";

html, body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: $base-font-family;
  font-size: $base-font-size-web;
}

a {
  color: $action-color-1;
  text-decoration: none;
}

form {
  .read-only-field {

    &__container {
      padding: 0 4px;
      margin-top: -4px;
    }

    &__label {
      margin-bottom: 8px;
      color: $foundational-color-7;
      font-size: 12px;
    }

    &__value {
      // use if needed
    }

  }
}

// Feedback
.xpo-Feedback {
  width: 500px;

  .mat-dialog-content {
    overflow: hidden;
    display: unset !important;
  }


  .mat-form-field {
    width: 100%;
  }

  .mat-dialog-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .xpo-DialogCloseButton {
      $_ngx-CloseButton--width: 20px;

      .mat-icon-button {
        height: auto;
        line-height: $xpo-lineHeight--base;
        width: auto;

        &,
        &:hover,
        &:focus {
          color: $xpo-grey--950;
        }

        .mat-icon {
          width: $_ngx-CloseButton--width;
        }
      }
    }
  }

  &-title {
    margin: $xpo-contentSpacing $xpo-contentSpacing 0 $xpo-contentSpacing;
  }

  &-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button:last-child {
      margin-right: $xpo-contentSpacing / 2;
      background-color: $action-color-1;
      color: white;
    }
  }
}
